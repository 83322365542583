<template>
  <div class="utility-model-page">
    <el-form ref="addForm" label-width="130px" :rules="rules" :model="addForm">
      <el-form-item label="证书号:" prop="ArchivesCode">
        <el-input v-model="addForm.ArchivesCode"></el-input>
      </el-form-item>
      <el-form-item label="实用新型名称:" prop="ArchivesName">
        <el-input v-model="addForm.ArchivesName"></el-input>
      </el-form-item>
      <el-form-item label="发明人:" prop="ArchivesUser">
        <el-input v-model="addForm.ArchivesUser"></el-input>
      </el-form-item>
      <el-form-item label="专利号:" prop="ArchivesNumber">
        <el-input v-model="addForm.ArchivesNumber"></el-input>
      </el-form-item>
      <el-form-item label="专利申请日:" prop="IssuingDate">
        <el-date-picker
          v-model="addForm.IssuingDate"
          type="date"
          placeholder="选择日期"
          format="yyyy 年 MM 月 dd 日"
          value-format="yyyy-MM-dd">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="专利权人:" prop="PatentUser">
        <el-input v-model="addForm.PatentUser"></el-input>
      </el-form-item>
      <el-form-item label="地址:" prop="Address">
        <el-input v-model="addForm.Address"></el-input>
      </el-form-item>
      <el-form-item label="授权公告日期:" prop="AuthorizationDate">
        <el-date-picker
          v-model="addForm.AuthorizationDate"
          type="date"
          placeholder="选择日期"
          format="yyyy 年 MM 月 dd 日"
          value-format="yyyy-MM-dd">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="授权公告号:" prop="AuthorizationNumber">
        <el-input v-model="addForm.AuthorizationNumber"></el-input>
      </el-form-item>
      <el-form-item label="备注:" prop="Remarks">
        <el-input type="textarea" v-model="addForm.Remarks"></el-input>
      </el-form-item>
      <el-form-item label="附件:">
        <el-upload
          class="upload-demo"
          ref="newupload"
          accept=".xlsx,.xls,.jpg,.mp4,.mp3,.png,.txt,.svg,.pdf,.docx,.rar,.ppt,.doc,.zip"
          multiple
          action="#"
          :file-list="fileList"
          :auto-upload="false"
          :on-change="handleFileChange"
          :before-remove="handleRemove"
        >
          <el-button size="medium" plain icon="el-icon-top" style="color: #4d90f1;border: 1px solid #4d90f1">上传文件</el-button>
        </el-upload>
      </el-form-item>
    </el-form>
    <div class="btn1">
      <el-button  @click="cancel('addForm')">取消</el-button>
      <el-button  @click="onSumbit('addForm')" :loading="loading">确定</el-button>
    </div>
  </div>
</template>

<script>
import {ruleData} from "@/utils/validationRules";
import {addManagement, TopicArchivesManagementData} from "@/api/fileManagement";
import {mapState} from "vuex";

export default {
  data() {
    return {
      loading: false,
      fileList: [],
      addForm: {

      },
      rules: ruleData
    }
  },
  computed: mapState(["projectId"]),
  created() {
    this.addForm = JSON.parse(JSON.stringify(TopicArchivesManagementData))
    this.addForm.Topicid = this.projectId
    this.addForm.Type = 4
  },
  methods: {
    handleRemove(file, fileList) { // 文件选择之后取消
      this.fileList = fileList;
    },
    handleFileChange(file, fileList) {
      this.fileList = fileList;
    },
    cancel(formName) { // 取消
      this.$refs[formName].resetFields();
      this.$emit("patent-child",false)
    },
    onSumbit(formName) { //上传提交
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let fd = new FormData();
          this.fileList.forEach(file => {
            fd.append('file',file.raw)
          })
          fd.append("data", JSON.stringify(this.addForm));
          this.loading = true
          addManagement(fd).then( res=> {
            console.log(res)
            if (res.data.Status == 1) {
              this.loading = false
              this.$message.success(res.data.Message)
              this.$refs[formName].resetFields();
              this.$emit("patent-child",true)
            } else {
              this.loading = false
              this.$message.error(res.data.Message)
            }
          })
          
        } else {
          return false;
        }
      });
    }
  }
}
</script>

