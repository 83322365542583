<template>
  <div class="common-voucher-page">
    <div class="content_left">
      <treedata @projectId-child="projectIdClick"></treedata>
    </div>
    <div class="common-voucher-content">
      <div class="voucher-header-upload">
        <span>关键字:</span>
        <el-input placeholder="请输入关键字" v-model="Key"></el-input>
        <span>所属单位:</span>
        <el-input placeholder="请输入" v-model="HospitalName"></el-input>
        <span>序号:</span>
        <el-input placeholder="请输入" v-model="SerialNumber"></el-input>
        <span>专利类型:</span>
        <el-select v-model="optionValue" placeholder="请选择" >
          <el-option
            v-for="item in option"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
            <span style="float: left">{{ item.label }}</span>
          </el-option>
        </el-select>
        <el-button type="primary" icon="el-icon-search" @click="queryList">查询</el-button>
<!--        <el-button type="primary" icon="el-icon-top" @click="add">上传专利</el-button>-->
      </div>
      <div class="file-down-export" style="margin-bottom: 15px">
        <el-button type="primary" icon="el-icon-top" @click="add">上传专利</el-button>
        <el-button type="primary" icon="el-icon-bottom" @click="downFiles">打包下载</el-button>
        <el-button type="primary" icon="el-icon-bottom" @click="exportFiles">导出表格</el-button>
      </div>
      <div style="display: flex;position: relative">
        <div class="content" style="height: 610px;width: 100%">
          <el-table
            :data="tableData"
            max-height="610"
            :row-key="getRowKey"
            border
            fit
            ref="multipleTable"
            @selection-change="handleSelectionChange"
          >
            <el-table-column
            :reserve-selection="true"
              align="center"
              type="selection"
              width="55">
            </el-table-column>
            <el-table-column
              label="序号"
              type="index"
              align="center"
              width="62">
            </el-table-column>
            <el-table-column
              label="名称"
              prop="ArchivesName"
              align="center"
              sortable
              min-width="150">
              <template slot-scope="scope">
                <span style="cursor: pointer" @click="patentSee(scope.row)">{{scope.row.ArchivesName}}</span>
              </template>
            </el-table-column>
            <el-table-column
              label="专利类型"
              prop="Type"
              align="center"
              sortable
              width="150">
              <template slot-scope="scope">
                <span v-show="scope.row.Type == 3">发明专利</span>
                <span v-show="scope.row.Type == 4">实用新型专利</span>
                <span v-show="scope.row.Type == 5">外观设计专利证书</span>
                <span v-show="scope.row.Type == 6">软件著作权</span>
              </template>
            </el-table-column>
            <el-table-column
              label="证书号"
              prop="ArchivesCode"
              align="center"
              sortable
              width="200">
            </el-table-column>
            <el-table-column
              label="上传时间"
              prop="CreateDate"
              align="center"
              sortable
              :formatter="formatter"
              width="150">
            </el-table-column>
            <el-table-column
              prop="ArchivesUser"
              label="发明/设计/著作权人"
              align="center"
              sortable
              width="190">
            </el-table-column>
            <el-table-column
              prop="SerialNumber"
              label="课题/子课题序号"
              align="center"
              sortable
              min-width="170">
            </el-table-column>
            <el-table-column
              prop="HospitalName"
              label="所属单位"
              align="center"
              sortable
              min-width="200">
            </el-table-column>
            <el-table-column
              prop="ArchivesUser"
              label="专利号"
              align="center"
              sortable
             min-width="120px">
            </el-table-column>
            <el-table-column
              prop="IssuingDate"
              label="专利申请日"
              align="center"
              sortable
              :formatter="formatter"
              width="140">
            </el-table-column>
            <el-table-column
              prop="PatentUser"
              label="专利权人"
              align="center"
              sortable
              width="120">
            </el-table-column>
            <el-table-column
              prop="AuthorizationDate"
              label="授权公告日期"
              align="center"
              :formatter="formatter"
              sortable
              width="150">
            </el-table-column>
            <el-table-column
              prop="AuthorizationNumber"
              label="授权公告号"
              align="center"
              sortable
              width="140">
            </el-table-column>
            <el-table-column
              prop="DevelopmentDate"
              label="开发完成日期"
              align="center"
              :formatter="formatter"
              sortable
              width="150">
            </el-table-column>
            <el-table-column
              prop="FirstDate"
              label="首次发表日期"
              :formatter="formatter"
              align="center"
              sortable
              width="150">
            </el-table-column>
            <el-table-column
              prop="Scope"
              label="权力范围"
              align="center"
              sortable
              width="130">
            </el-table-column>
            <el-table-column
              prop="GetDate"
              label="证书获得日期"
              :formatter="formatter"
              align="center"
              sortable
              width="150">
            </el-table-column>
            <el-table-column
              prop="Address"
              label="地址"
              align="center"
              sortable
             >
            </el-table-column>
            <el-table-column
              prop="Remarks"
              label="备注"
              align="center"
              sortable
              width="120"
              >
            </el-table-column>
            <el-table-column label="操作" align="center" width="240" fixed="right">
              <template slot-scope="scope">
                <el-link
                  style="margin-right: 11px"
                  type="text"
                  :underline="false"
                  @click="edit(scope.row)"
                ><i class="el-icon-edit-outline"></i>编辑</el-link>
                <el-link
                  style="margin-right: 11px"
                  type="text"
                  :underline="false"
                  @click="delFinance(scope.row)"
                ><i class="el-icon-delete"></i>删除</el-link>
                <el-link
                  type="text"
                  :underline="false"
                  @click="showFile(scope.row)"
                ><img src="../../../assets/image/notice/query.png" alt="" style="width: 16px;height: 16px;vertical-align: -3px">查看附件</el-link>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="block">
        <el-pagination
          background
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-size="eachpage"
          layout="total,sizes,prev, pager, next, jumper"
          @size-change="handleSizeChange"
            :page-sizes="[10, 20, 30, 40,50,60,70,80,90,100]"
          :total="PageCount">
        </el-pagination>
      </div>
    </div>
    <!--      </div>-->
    <div class="voucher-upload">
      <el-dialog
        top="4vh"
        :visible.sync="dialogVisible"
        v-dialogDrag
      >
        <div slot="title" class="header-title">
          <span class="title-name"></span>
          <span class="title-age">上传专利</span>
        </div>
        <el-form label-width="130px">
          <el-form-item label="专利类型:">
            <el-radio v-model="radio" label="1" style="width: 110px;">发明专利</el-radio>
            <el-radio v-model="radio" label="2">实用新型专利</el-radio>
            <el-radio v-model="radio" label="3" style="width: 110px;">软件著作权</el-radio>
            <el-radio v-model="radio" label="4">外观设计专业证书</el-radio>
          </el-form-item>
        </el-form>
        <keep-alive v-if="dialogVisible">
          <Invention v-if="radio==1" @patent-child="patentClick"></Invention>
          <utility-model v-if="radio==2" @patent-child="patentClick"></utility-model>
          <software-copyright v-if="radio==3" @patent-child="patentClick"></software-copyright>
          <appearance-design v-if="radio==4" @patent-child="patentClick"></appearance-design>
        </keep-alive>
      </el-dialog>
    </div>
    <div class="voucher-upload">
      <el-dialog
        :visible.sync="dialogEdit"
        top="4vh"
        v-dialogDrag
      >
        <div slot="title" class="header-title">
          <span class="title-name"></span>
          <span class="title-age">编辑</span>
        </div>
        <edit-invention :Type="1" :patentId="editId" v-if="patentType == 1 && editSeeType ==1" @patent-child="patentEditClick" :key="editId"></edit-invention>
        <edit-model :Type="1" :patentId="editId" v-if="patentType == 2 && editSeeType ==1" @patent-child="patentEditClick" :key="editId"></edit-model>
        <edit-software :Type="1" :patentId="editId" v-if="patentType == 3 && editSeeType ==1" @patent-child="patentEditClick" :key="editId"></edit-software>
        <edit-design :Type="1" :patentId="editId" v-if="patentType == 4 && editSeeType ==1" @patent-child="patentEditClick" :key="editId"></edit-design>
      </el-dialog>
    </div>
    <div class="voucher-upload">
      <el-dialog
        :visible.sync="dialogSee"
        top="4vh"
        v-dialogDrag
      >
        <div slot="title" class="header-title">
          <span class="title-name"></span>
          <span class="title-age">查看</span>
        </div>
        <edit-invention :Type="2" :patentId="editId" v-if="patentType == 1 && editSeeType ==2" :key="editId"></edit-invention>
        <edit-model :Type="2" :patentId="editId" v-if="patentType == 2 && editSeeType ==2" :key="editId"></edit-model>
        <edit-software :Type="2" :patentId="editId" v-if="patentType == 3 && editSeeType ==2" :key="editId"></edit-software>
        <edit-design :Type="2" :patentId="editId" v-if="patentType == 4 && editSeeType ==2" :key="editId"></edit-design>
      </el-dialog>
    </div>
    <div class="commonVoucher-dialog">
      <el-dialog
        top="35vh"
        :visible.sync="delFilesDialog"
        width="510px"
        v-dialogDrag
      >
        <div slot="title" class="header-title">
          <span class="title-name"></span>
          <span class="title-age">提示</span>
        </div>
        <div style="text-align: center">
          <i class="el-icon-warning" style="color: #FFBA00;font-size: 24px ;margin-right: 5px"></i>
          <span style="font-size: 18px;color: #666">此操作将永久删除该选择的专利文件，是否继续？</span>
        </div>
        <div class="btn">
          <button  @click="delFilesDialog = false">取消</button>
          <button @click="submitDel">确定</button>
        </div>
      </el-dialog>
    </div>
    <div class="commonVoucher-dialog">
      <el-dialog
        top="35vh"
        :visible.sync="delFinanceDialog"
        width="510px"
        v-dialogDrag
      >
        <div slot="title" class="header-title">
          <span class="title-name"></span>
          <span class="title-age">提示</span>
        </div>
        <div style="text-align: center">
          <i class="el-icon-warning" style="color: #FFBA00;font-size: 24px ;margin-right: 5px"></i>
          <span style="font-size: 18px;color: #666">此操作将永久删除该专利文件，是否继续？</span>
        </div>
        <div class="btn">
          <button  @click="delFinanceDialog = false">取消</button>
          <button @click="sumDelFinance">确定</button>
        </div>
      </el-dialog>
    </div>
    <div class="dialog-file">
      <el-dialog
        top="12vh"
        :visible.sync="dialogFile"
        width="800px"
        v-dialogDrag
        v-if="dialogFile"
      >
        <div slot="title" class="header-title">
          <span class="title-name"></span>
          <span class="title-age">附件列表</span>
        </div>
        <file-list :DataGuid="FileDataGuid"></file-list>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import FileList from "@/components/Management/FileList";
import reseacrh from "../../../api/research.js"
import {mapState} from "vuex";
import treedata from "@/components/treedata";
import {downloadA, downloadFileId,downLoadXls} from "@/utils/downloadUrl";
import Invention from "@/components/Patent/Invention";
import UtilityModel from "@/components/Patent/UtilityModel";
import SoftwareCopyright from "@/components/Patent/SoftwareCopyright";
import AppearanceDesign from "@/components/Patent/AppearanceDesign";
import EditInvention from "@/components/Patent/EditInvention";
import EditModel from "@/components/Patent/EditModel";
import EditSoftware from "@/components/Patent/EditSoftware";
import EditDesign from "@/components/Patent/EditDesign";
import {delManagement, exportTableManagement, GetPagedManagement} from "@/api/fileManagement";

export default {
  data(){
    return{
      FileDataGuid: [],
      showFileList: [], // 查看图片的列表
      dialogFile: false, // 查看附件弹框
      exportData: "ArchivesName,TypeName,ArchivesCode,CreateDate,ArchivesUser,SerialNumber,HospitalName," +
        "ArchivesNumber,IssuingDate,PatentUser,AuthorizationDate,AuthorizationNumber,DevelopmentDate,FirstDate," +
        "Method,Scope,GetDate,Address,Remarks",
      exportDataName: "名称,专利类型,证书号,上传时间,发明/设计/著作权人,课题/子课题序号,所属单位,专利号,专利申请日,专利权人," +
        "授权公告日期,授权公告号,开发完成日期,首次发表日期,权利取得方式,权利范围,证书获得日期,地址,备注",
      Type: [3,4,5,6],
      optionValue: "",
      option: [
        {
          value: "",
          label: "全部专利"
        },
        {
          value: "3",
          label: "发明专利"
        },
        {
          value: "4",
          label: "实用新型专利"
        },
        {
          value: "5",
          label: "外观设计专利"
        },
        {
          value: "6",
          label: "软件著作权"
        },
      ],
      SerialNumber: "",
      HospitalName: "",
      editSeeType: "",
      patentType: "",
      editId: 0,
      dialogSee: false, // 查看弹框
      radio: "1",
      addLoading: false,
      DataGuidList: [], // 下载拼接的数组DataGuid
      api2: window.global_config.BASE_URL1,
      api: window.global_config.BASE_URL1 + "/TopicFiles/",
      imgList: [],
      UserName: window.sessionStorage.getItem('UserName'),
      fileLists: [],//文件列表
      DataGuid: "", // 编辑的DataGuid
      tableDataSee: [], //编辑查看到的数据列表
      delFinanceItem: {}, // 删除凭证的对象
      delFinanceDialog: false, // 删除凭证的弹框
      delFileId: null, // 删除文件的Id
      delFilesDialog: false, // 删除文件的弹框
      dialogEdit: false, // 编辑的弹框
      editForm: { // 编辑对象
      },
      Key: "",
      dialogVisible:false, //上传文件弹框
      multipleSelection: [],//多选框数据
      tableData: [],
      imglist:[],
      TotalPages:0,
      imgLists: [], // 项目的所有图片列表
      currentPage:1,
      PageCount:0,
      eachpage:10
    }
  },
  computed: mapState(["projectId"]),
  components:{
    treedata, Invention, UtilityModel, SoftwareCopyright, AppearanceDesign, EditInvention,
    EditModel, EditSoftware, EditDesign,FileList
  },
  created(){
    this.filelist()
  },
  methods:{
    handleSizeChange(val){  //切换每页条数
      this.eachpage = val
      this.filelist()
    },
    getRowKey(row){     //回显
      console.log(row);
      return row.Id;
    },
    showFile(item) { // 查看附件
      console.log(item,'专利')
      reseacrh.preFinancFile(item.DataGuid).then(res=>{
        if(res.data.Status==1){
          this.showFileList=res.data.ExtraData
          if (this.showFileList.length == 0) {
            this.$message.error('您还没有上传附件')
          } else if(this.showFileList.length == 1) {
            this.pre(this.showFileList[0])
          } else {
            this.FileDataGuid = res.data.ExtraData
            this.dialogFile = true
          }
        }else{
          alert(res.data.Message)
        }
      })

    },
    patentSee(item) { // 专利的查看
      this.editId = item.Id
      this.editSeeType = 2 // 防止组件重复刷新数据请求
      if (item.Type== 3) {this.patentType = 1} // 发明
      if (item.Type== 4) {this.patentType = 2} // 实用
      if (item.Type== 6) {this.patentType = 3} // 软件
      if (item.Type== 5) {this.patentType = 4} // 外观
      this.dialogSee = true
    },
    patentEditClick(data) { // 编辑专利组件取消确定的弹框取消
      this.dialogEdit = false
      if (data) {
        this.filelist()
      }
    },
    patentClick(data) { // 专利组件取消确定的弹框取消
      this.dialogVisible = false
      if (data) {
        this.filelist()
      }
    },
    formatter(row,column) { // 时间排序转换格式
      return row.CreateDate.substring(0,10)
    },
    downloadimgList(src,name) { // 已知url
      downloadA(src,name)
    },
    preimgList(Url){ // 项目进来时图片列表的预览
      window.open(this.api2+Url);
    },
    projectIdClick(data) { //树型结构传递过来的课题id
      this.$store.commit('SaveId',data)
      this.currentPage = 1;
      this.eachpage = 10;
      this.PageCount = 0
      this.filelist()
    },
    handleRemove(file, fileList) {
      this.fileLists = fileList;
    },
    handleFileChange(file, fileList) {
      this.fileLists = fileList;
      // console.log(this.fileLists)
    },
    queryList() { // 查询
      this.filelist()
    },
    add() { // 上传专利文件
      this.dialogVisible=true
    },
    edit(item) { //编辑
      console.log(item,'编辑')
      this.editId = item.Id
      this.editSeeType = 1  // "编辑为1"
      console.log(item)
      if (item.Type== 3) {
        this.patentType = 1
      } // 发明
      if (item.Type== 4) {
        this.patentType = 2
      } // 实用
      if (item.Type== 6) {
        this.patentType = 3
      } // 软件
      if (item.Type== 5) {
        this.patentType = 4
      } // 外观
      this.DataGuid = item.DataGuid
      this.dialogEdit = true
    },
    handleSelectionChange(val) { //多选
      this.multipleSelection = val;
    },
    handleCurrentChange(val){
      this.currentPage=val
      this.filelist()
    },
    pre(item){ // 点开查看文件
      reseacrh.preview(item.Id).then(res=>{
        // console.log(res);
        // if(res.data.Status==1){
        //   let file = window.global_config.BASE_URL1+res.data.ExtraData.Url
        //   if(/\.(pdf|PDF|png|PNG|jpg|JPG|jpeg)$/.test(file)){
        //     window.open(window.global_config.BASE_URL1+res.data.ExtraData.Url);
        //   }else{
        //     this.$message.warning("暂时只支持pdf文件预览,请下载查看")
        //     this.downloadfile(item.Id)
        //     // window.open(window.global_config.BASE_URL1+res.data.ExtraData.Url);
        //   }
        // }
        this.downloadfile(item.Id,true)
      })
    },
    filelist(){
      if (this.optionValue == "") {
        this.Type = [3,4,5,6]
      } else {
        this.Type = [...this.optionValue]
      }
      var fileobj={
        TopicId:this.projectId,
        Key: this.Key,
        Type: this.Type,
        SerialNumber: this.SerialNumber,
        HospitalName: this.HospitalName,
        PageIndex:this.currentPage-1,
        PageSize:this.eachpage,
      }
      GetPagedManagement(fileobj).then(res=>{
        if(res.data.Status==1){
          // this.imglist=res.data.Entity
          this.tableData = res.data.Entity
          this.PageCount=res.data.TotalRowsCount
        }else{
          this.$message.error(res.data.Message)
        }
      })
    },
    submitDel() { // 提交删除单个文件
      reseacrh.alldelet({Id:this.delFileId}).then(res=>{
        if(res.data.Status==1){
          reseacrh.preFinancFile(this.DataGuid).then(res=>{
            if (res.data.Status==1) {
              this.tableDataSee = res.data.ExtraData
              this.delFilesDialog = false
            }
          })
          this.$message.success(res.data.Message)
        }else{
          this.$message.error(res.data.Message)
        }
      })
    },
    sumDelFinance() { // 确定提交删除
      delManagement({Id:this.delFinanceItem.Id}).then(res=>{
        // console.log(res)
        if(res.data.Status==1){
          this.$message.success(res.data.Message)
          this.delFinanceDialog = false
          this.filelist()
        }else{
          this.$message.error(res.data.Message)
        }
      })
    },
    delFinance (item) { // 删除凭证弹框
      this.delFinanceDialog = true
      this.delFinanceItem = item
    },
    deletefile(id){//删除文件
      this.delFileId = id
      this.delFilesDialog = true
    },
    downFiles() { // 凭证下载导出图片
      this.DataGuidList = []
      if (this.multipleSelection.length === 0) {
        this.$message.error('请选择要下载的文件')
        return  false
      }
      console.log(this.multipleSelection)
      this.multipleSelection.forEach(item => {
        this.DataGuidList.push(item.DataGuid)
      })
      reseacrh.FinancZFileZip({DataGuids: this.DataGuidList}).then(res => {
        console.log(res,'拼接图片')
        if (res.status == 200) {
          let data = res.data;
          // let filename = decodeURIComponent(res.headers.filename)
          downLoadXls(data,"专利.zip")
        } else {
          this.$message.error("文件不存在")
        }
      })
    },
    downloadfile(id ,preview=false){
      downloadFileId(id ,preview)
    },
    exportFiles() {
      if (this.optionValue == "") {
        this.Type = [3,4,5,6]
      } else {
        this.Type = [...this.optionValue]
      }
      var exportobj={
        TopicId:this.projectId,
        Key: this.Key,
        Type: this.Type,
        SerialNumber: this.SerialNumber,
        HospitalName: this.HospitalName,
        PageIndex:this.currentPage-1,
        PageSize:this.eachpage,
        titleName: this.exportDataName,
        titleField: this.exportData,
        ExportFileName: "专利"

      }
      exportTableManagement(exportobj).then(res=> {
        if (res.status == 200) {
          let data = res.data;
          let filename = decodeURIComponent(res.headers.filename)
          downLoadXls(data,filename)
        } else {
          this.$message.error("导出失败")
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
@import "../../../styles/file_list.scss";
.common-voucher-page {
  /deep/ .el-dialog__body {
    padding-top: 0!important;
  }
}

</style>
