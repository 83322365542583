<template>
  <div class="design-page">
    <el-form ref="editForm" label-width="150px" :rules="rules" :model="editForm">
      <el-form-item label="专利类型:">
        <el-radio v-model="radio2" label="1">软件著作权</el-radio>
      </el-form-item>
      <el-form-item label="证书号:" prop="ArchivesCode">
        <el-input v-model="editForm.ArchivesCode"></el-input>
      </el-form-item>
      <el-form-item label="软件名称:" prop="ArchivesName">
        <el-input v-model="editForm.ArchivesName"></el-input>
      </el-form-item>
      <el-form-item label="著作权人:" prop="ArchivesUser">
        <el-input v-model="editForm.ArchivesUser"></el-input>
      </el-form-item>
      <el-form-item label="开发完成日期:" prop="DevelopmentDate">
        <el-date-picker
          v-model="editForm.DevelopmentDate"
          type="date"
          placeholder="选择日期"
          format="yyyy 年 MM 月 dd 日"
          value-format="yyyy-MM-dd">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="首次发表日期:" prop="FirstDate">
        <el-date-picker
          v-model="editForm.FirstDate"
          type="date"
          placeholder="选择日期"
          format="yyyy 年 MM 月 dd 日"
          value-format="yyyy-MM-dd">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="权利取得方式:" prop="Method">
        <el-input v-model="editForm.Method"></el-input>
      </el-form-item>
      <el-form-item label="权利范围:" prop="Scope">
        <el-input v-model="editForm.Scope"></el-input>
      </el-form-item>
      <el-form-item label="证书获得日期:" prop="GetDate">
        <el-date-picker
          v-model="editForm.GetDate"
          type="date"
          placeholder="选择日期"
          format="yyyy 年 MM 月 dd 日"
          value-format="yyyy-MM-dd">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="备注:" prop="Remarks">
        <el-input type="textarea" v-model="editForm.Remarks" :readonly="Type == 2"></el-input>
      </el-form-item>
      <el-form-item label="附件:">
        <div style="display:flex;flex-wrap: wrap;">
          <management-list :DataGuid="editForm.DataGuid" :key="editForm.DataGuid + keyId " :Type="Type"></management-list>
          <div v-if="Type == 1">
            <el-upload
              class="upload-demo"
              ref="newupload"
              accept=".xlsx,.xls,.jpg,.mp4,.mp3,.png,.txt,.svg,.pdf,.docx,.rar,.ppt,.doc,.zip"
              multiple
              action="#"
              :file-list="fileList"
              :auto-upload="false"
              :on-change="handleFileChange"
              :before-remove="handleRemove"
            >
              <el-button size="medium" plain icon="el-icon-top" style="color: #4d90f1;border: 1px solid #4d90f1">上传文件</el-button>
            </el-upload>
          </div>
        </div>
      </el-form-item>
    </el-form>
    <div class="btn1" v-if="Type == 1">
      <el-button  @click="cancel('editForm')">取消</el-button>
      <el-button  @click="onSumbit('editForm')" :loading="loading">确定</el-button>
    </div>
  </div>
</template>

<script>
import {ruleData} from "@/utils/validationRules";
import { editManagement, infoManagement } from "@/api/fileManagement";
export default {
  data() {
    return {
      keyId: "",
      radio2: "1",
      loading: false,
      fileList: [],
      editForm: {
      },
      ResetForm: {},
      rules: ruleData
    }
  },
  props: ["Type","patentId"],
  created() {
    this.getInfo()
  },
  methods: {
    getInfo() {
      infoManagement({Id: this.patentId}).then(res => {
        if (res.data.Status == 1) {
          console.log(res, "编辑数据")
          this.editForm = res.data.ExtraData
          this.ResetForm = JSON.parse(JSON.stringify(this.editForm))  // 防止赋值同时改变
        }
      })
    },
    handleRemove(file, fileList) { // 文件选择之后取消
      this.fileList = fileList;
    },
    handleFileChange(file, fileList) {
      this.fileList = fileList;
    },
    cancel(formName) { // 取消
      this.editForm = this.ResetForm
      this.$emit("patent-child",false)
    },
    onSumbit(formName) { //上传编辑提交
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let fd = new FormData();
          this.fileList.forEach(file => {
            fd.append('file',file.raw)
          })
          fd.append("data", JSON.stringify(this.editForm));
          editManagement(fd).then((res) => {
            if (res.data.Status == 1) {
              // this.$refs[formName].resetFields();
              if (this.fileList.length> 0) {
                this.keyId++
              }
              this.fileList = []
              this.$emit("patent-child",true)
              this.$message.success(res.data.Message);
            }else{
              this.editForm = this.ResetForm
              this.$message.error(res.data.Message)
            }
          });
        } else {
          this.$message.error('请注意必填项');
          return false;
        }
      });
    }
  }
}
</script>
